import { useState, useEffect } from "react";
import {
  Container,
  Body,
  Content,
  ContentHeader,
} from "../components/Container.Style";
import {
  CountCardsContainer,
  MainGraphContainer,
} from "../components/Analytics/Analytics.Style";

import Button from "../components/Input/Button";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import CountCard from "../components/Analytics/CountCard";
import MainGraph from "../components/Analytics/MainGraph";

import analyticServices from "../services/analytics.services";
import conferenceServices from "../services/conference.services";
import AcquaintanceAnalytics from "./AcquaintanceAnalytics";

import { fillData } from "../utils/tool";
import { months } from "../utils/date";
import Select from "../components/Input/Select";
import MessagesGraph from "./MessagesAnalytics";

const styles = {
  container: {
    display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    // height: "100vh",
    // backgroundColor: "#f4f7fc",
    // padding: "20px",
  },
  dropdownContainer: {
    // backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    width: "300px",
    // textAlign: "center",
  },
  dropdownLabel: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "10px",
    display: "block",
  },
  dropdown: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
    color: "#333",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  dropdownHoverFocus: {
    borderColor: "#007bff",
    backgroundColor: "#e8f4ff",
  },
  selectedConference: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    width: "300px",
    textAlign: "center",
  },
  selectedHeader: {
    fontSize: "22px",
    color: "#007bff",
    marginBottom: "10px",
  },
};

function Dashboard(props) {
  const [filter, setFilter] = useState("daily");
  const [tab, setTab] = useState("users");
  const [conferences, setConferences] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [acquaintancesScanTotalCount, setAcquaintancesScanTotalCount] =
    useState(0);
  const [messagesTotalCount, setMessagesScanTotalCount] = useState(0);

  const [data, setData] = useState({
    daily: {
      users: [],
      scans: [],
      logins: [],
      timeSpends: [],
    },
    monthly: {
      users: [],
      scans: [],
      logins: [],
    },
  });

  const [selectedConference, setSelectedConference] = useState(null);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedConference = conferences.find(
      (conference) => conference._id === selectedId
    );
    setSelectedConference(selectedConference);
  };

  const setLabel = (data) => {
    if (filter === "monthly") {
      return `${months[data.month]} ${data.year}`;
    } else {
      return `${data.day} ${months[data.month]}`;
    }
  };

  const getTotalCounts = (data) => {
    var counts = 0;
    data.map((data) => (counts += data.count));
    return counts;
  };

  const totalTimeInHour = (data) => {
    const counts = getTotalCounts(data);
    return `${(counts / (60 * 60 * 1000)).toPrecision(2)}`;
  };

  const mapTimeSpends = (data) => {
    return {
      ...data,
      count: (data.count / (60 * 60 * 1000)).toPrecision(2),
    };
  };

  useEffect(() => {
    (async () => {
      conferenceServices
        .getConferences()
        .then((res) => setConferences(res.data.conferences))
        .catch((err) => console.log(err.message));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedConference) {
        await analyticServices
          .fetchAttendeesReport(
            `filter=monthly&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.monthly.users = fillData(res.data, "monthly");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchAttendeesReport(
            `filter=daily&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.daily.users = fillData(res.data, "daily");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchScansReport(
            `filter=monthly&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.monthly.scans = fillData(res.data, "monthly");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchScansReport(
            `filter=daily&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.daily.scans = fillData(res.data, "daily");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchLoginsReport(
            `filter=monthly&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.monthly.logins = fillData(res.data, "monthly");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchLoginsReport(
            `filter=daily&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.daily.logins = fillData(res.data, "daily");
          })
          .catch((err) => {
            console.log(err.message);
          });

        await analyticServices
          .fetchTimeSpendReport(
            `filter=daily&conferenceId=${
              selectedConference ? selectedConference._id : 0
            }`
          )
          .then((res) => {
            //fill empty data and map label
            data.daily.timeSpends = fillData(res.data, "daily");
          })
          .catch((err) => {
            console.log(err.message);
          });

        setData({ ...data });
      }
    })();
  }, [filter, selectedConference]);

  useEffect(() => {
    if (conferences && conferences.length > 0) {
      setSelectedConference(conferences[0]);
    }
  }, [conferences]);

  return (
    <Container>
      <Header name={"Abdullah"} />
      <Body>
        <Sidebar />
        <Content>
          <CountCardsContainer>
            <CountCard
              id="1"
              label={"Users added"}
              setLabel={setLabel}
              data={data.daily.users}
              count={getTotalCounts(data.daily.users)}
              color="#f7ad00"
            />
            <CountCard
              id="2"
              label={"Total Login"}
              setLabel={setLabel}
              data={data.daily.logins}
              count={getTotalCounts(data.daily.logins)}
              color="#DC3CCC"
            />
            <CountCard
              id="3"
              label={"QR Scans"}
              setLabel={setLabel}
              data={data.daily.scans}
              count={acquaintancesScanTotalCount}
              color="#2B98D6"
            />
            {/* <CountCard
              id="4"
              setLabel={setLabel}
              data={data.daily.timeSpends.map(mapTimeSpends)}
              label={"Time Spent"}
              count={totalTimeInHour(data.daily.timeSpends)}
              color="#5F5F5F"
            /> */}
            <CountCard
              id="3"
              label={"Total Messages"}
              setLabel={setLabel}
              data={[]}
              count={messagesTotalCount}
              color="#2B98D6"
            />
          </CountCardsContainer>
          <MainGraphContainer>
            <ContentHeader>
              <div className="head-block">
                <h5>Graph Analytics</h5>
              </div>

              <div style={styles.container}>
                <div style={styles.dropdownContainer}>
                  <label htmlFor="conference" style={styles.dropdownLabel}>
                    Select Conference:
                  </label>
                  <select
                    id="conference"
                    value={selectedConference ? selectedConference._id : ""}
                    onChange={handleSelectChange}
                    style={
                      isHovered
                        ? { ...styles.dropdown, ...styles.dropdownHoverFocus }
                        : styles.dropdown
                    }
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <option value="">-- Select --</option>
                    {conferences.map((conference) => (
                      <option key={conference._id} value={conference._id}>
                        {conference.name} | {conference.address}
                      </option>
                    ))}
                  </select>
                </div>

                {/* {selectedConference && (
                  <div style={styles.selectedConference}>
                    <h3 style={styles.selectedHeader}>Selected Conference:</h3>
                    <p>
                      <strong>Conference ID:</strong> {selectedConference._id}
                    </p>
                    <p>
                      <strong>Conference Name:</strong>{" "}
                      {selectedConference.name}
                    </p>
                  </div>
                )} */}
              </div>
              <div className="head-block right">
                <Select
                  options={["Monthly", "Daily"].map((name) => {
                    return {
                      name,
                      value: name.toLocaleLowerCase(),
                    };
                  })}
                  selectedValue={filter === "daily" ? "Daily" : "Monthly"}
                  selectValue={(filter) => {
                    setFilter(filter.value);
                  }}
                />
              </div>
            </ContentHeader>
            <div
              style={{
                display: "grid",
                gridGap: "10px",
                gridTemplateColumns: "150px 150px 150px 150px",
              }}
            >
              <Button
                onClick={() => setTab("users")}
                btnType={`graphBtn ${
                  tab === "users" ? "" : "graphBtn-offline"
                }`}
                name="Users"
              />
              <Button
                onClick={() => setTab("logins")}
                btnType={`graphBtn ${
                  tab === "logins" ? "" : "graphBtn-offline"
                }`}
                name="Logins"
              />
              {/* <Button
                onClick={() => setTab("scans")}
                btnType={`graphBtn ${
                  tab === "scans" ? "" : "graphBtn-offline"
                }`}
                name="Scans"
              /> */}
              <Button
                onClick={() => setTab("acquaintancePerDay")}
                btnType={`graphBtn ${
                  tab === "acquaintancePerDay" ? "" : "graphBtn-offline"
                }`}
                name="Scan"
              />
              <Button
                onClick={() => setTab("messages")}
                btnType={`graphBtn ${
                  tab === "messages" ? "" : "graphBtn-offline"
                }`}
                name="Messages"
              />
            </div>
            {tab === "users" && (
              <MainGraph
                chartData={{
                  labels: data[filter].users.map(setLabel),
                  datasets: [
                    {
                      data: data[filter || "daily"].users.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.2,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}
            {tab === "scans" && (
              <MainGraph
                chartData={{
                  labels: data[filter].scans.map(setLabel),
                  datasets: [
                    {
                      data: data[filter || "daily"].scans.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.4,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}
            {tab === "logins" && (
              <MainGraph
                chartData={{
                  labels: data[filter || "daily"].logins.map(setLabel),
                  datasets: [
                    {
                      data: data[filter].logins.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.4,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}

            {selectedConference && selectedConference._id ? (
              <AcquaintanceAnalytics
                conferenceId={selectedConference._id}
                setAcquaintancesScanTotalCount={setAcquaintancesScanTotalCount}
                show={tab === "acquaintancePerDay"}
              />
            ) : null}

            <MessagesGraph
              setMessagesScanTotalCount={setMessagesScanTotalCount}
              show={tab === "messages"}
            />
          </MainGraphContainer>
        </Content>
      </Body>
    </Container>
  );
}

export default Dashboard;
