import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ORIGIN } from "../apis";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MessagesGraph = ({ show, setMessagesScanTotalCount }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch the message count per day data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(`${ORIGIN}/api/v1/messages/per-day`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          // body: JSON.stringify({ conferenceId }),
        });
        const result = await response.json();
        setData(result["messages"]); // Store the result in state
        setMessagesScanTotalCount(result["totalCount"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Prepare the data for the chart
  const chartData = {
    labels: data.map((item) => item.date), // Dates (e.g., '2023-10-01')
    datasets: [
      {
        label: "Messages Count",
        data: data.map((item) => item.count), // Message count for each date
        fill: false,
        borderColor: "#FF9F0F",
        tension: 0.1,
      },
    ],
  };

  // Chart.js options for better control (like height, etc.)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      {show ? (
        <div>
          {/* <h2>Messages per Day Across All Chats</h2> */}
          <div style={{ height: "400px", width: "100%" }}>
            <Line data={chartData} options={options} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MessagesGraph;
