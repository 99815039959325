import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow-y: scroll;
`;

export const ModalBody = styled.div`
  width: 90%;
  max-width: ${(props) => props.width || "430px"};
  padding: 1% 2% 2% 2%;
  margin: auto;
  background: #ffffff;
  border-radius: 37px;
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 4% 1.5%;
  border-bottom: 1px solid #f0f0f0;
  background: transparent;
  .left {
    display: flex;
  }
  .left > img {
    width: 25px;
    margin: auto 10px auto 0;
  }
  .left > span {
    font-family: GilroyMed;
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto 0;
    color: #414141;
  }
`;

export const ModalForm = styled.div`
  width: 95%;
  padding: 0 1.5%;
  margin: 0;
  background: transparent;
`;

export const ModalInputWrapper = styled.div`
  width: 100%;
  margin: 21px auto 0 auto;
  label {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 0.95rem;
    color: #414141;
  }
  .modal-input {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    margin: 7px 0 0 0;
    font-family: Gilroy;
    font-size: 1rem;
    outline-style: none;
    border: none;
    border-radius: 18px;
    background: #f5f5f5;
  }
  .modal-input:placeholder {
    font-size: 1rem;
    font-weight: 600;
  }
`;
