import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ORIGIN } from "../apis";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AcquaintanceAnalytics = ({
  conferenceId,
  setAcquaintancesScanTotalCount,
  show,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${ORIGIN}/api/v1/acquaintances/requests-per-day`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ conferenceId }),
          }
        );
        const result = await response.json();
        console.log("result", result);
        setAcquaintancesScanTotalCount(result[0].totalCount);
        setData(result[0].dailyCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (conferenceId) {
      fetchData();
    }
  }, [conferenceId]);

  const chartData = {
    labels: data.map((item) => item._id), // Date (e.g., "2023-09-24")
    datasets: [
      {
        label: "Acquaintance Requests",
        data: data.map((item) => item.count), // Count of requests for each date
        fill: false,
        borderColor: "#FF9F0F",
        tension: 0.1,
      },
    ],
  };

  const options = {
    // responsive: true,
    maintainAspectRatio: false, // Allow for custom aspect ratio
  };

  return (
    <>
      {show ? (
        <div>
          {/* <h2>Acquaintance Requests Per Day</h2> */}
          <Line
            data={chartData}
            //options={options} height={200}
          />
        </div>
      ) : null}
    </>
  );
};

export default AcquaintanceAnalytics;
